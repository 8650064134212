import { message } from 'ant-design-vue';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { localStorage } from '@/utils/storage';
import { useRouter } from "vue-router";
const router = useRouter();
const TokenKey = 'Admin-Token'
axios.defaults.withCredentials = true;

const service = axios.create({
  // baseURL: window.location.origin, // './'
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.film110.cn' :  window.location.origin, // './'
  // timeout: 500000,
  headers: { 
    'Content-Type': 'application/json;charset=utf-8',
    Authorization: localStorage.get(TokenKey)||'',
  }
});

// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {

    if (!config.headers) {
      throw new Error(
        `Expected 'config' and 'config.headers' not to be undefined`
      );

    } else{
      responseType: 'blob'
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response:  AxiosResponse & {error?:any} ) => {
    const { code, msg,status } = response.data;
    if(code === 401 || status  === 401){
      localStorage.clear();
      const href = location.href ;
      location.href = location.origin  +'#/login?redirect=' + href;
      router.push({
        path: '/login',
        query:{
          redirect: href
        }
      });
      return
    }
    if(response.status === 403){
      sessionStorage.removeItem('menu');
      sessionStorage.removeItem('Permissions');
      // location.href = 'https://www.casrap.com/console/login/'
    }  else if(status == 400){
  
      return response.data; 
    }else{
      return response.data; 
    }
    // return response.data;
    // if (code === '00000' || code === 10000) {
    // } else {
    //   // 响应数据为二进制流处理(Excel导出)
    //   if (response.data instanceof ArrayBuffer) {
    //     return response;
    //   }

    //   ElMessage({
    //     message: msg || '系统出错',
    //     type: 'error'
    //   });
    //   return Promise.reject(new Error(msg || 'Error'));
    // }
  },
  error => {
    // const { status } = error.response.data;
    const msg =  error.response.data.error

    if(error.response.status === 403){
      // location.href = 'https://www.casrap.com/console/login/'
    } 
    if(error.response.status === 400){
      // location.href = 'https://www.casrap.com/console/login/'
      return error.response.data; 
    } 

    if(error.response.status >=500)  {
      message.error(msg || '系统出错,刷新')
      // ElMessage({
      //   message: msg || '系统出错',
      //   type: 'error'
      // });
    }
    
    // if (code === 'A0230') {
    //   // token 过期
    //   localStorage.clear(); // 清除浏览器全部缓存
    //   window.location.href = '/'; // 跳转登录页
    //   ElMessageBox.alert('当前页面已失效，请重新登录', '提示', {});
    // } else {
    //   ElMessage({
    //     message: msg || '系统出错',
    //     type: 'error'
    //   });
    // }
    return Promise.reject( msg )
    // return Promise.reject(new Error(msg || 'Error'));
  }
);

// 导出 axios 实例
export default service;
