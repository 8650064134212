import { PermissionState } from '@/types/store/permission';
import { defineStore } from 'pinia';



// 获取权限管理接口
const usePermissionStore = defineStore({
  id: 'permission',
  state: (): PermissionState => ({
    permission:[], // 权限码列表
    routes: [], // 权限校验，是否有权限访问此页面
    menu: [],
    appList:[], // APP列表
  }),
  actions: {

  },
});

export default usePermissionStore;
