
  import request from '@/utils/request';
  import { AxiosPromise } from 'axios';
  /**
   * 登录
   * @param data
   */
  export function login(data: {user:string,pwd:string}): AxiosPromise<{state:string}> {
    return request({
      url: '/oauth/token',
      method: 'post',
      params: data,
      headers: {
        Authorization: 'Basic bWFsbC1hZG1pbi13ZWI6MTIzNDU2', // 客户端信息Base64明文：mall-admin-web:123456
      },
    });
  }
  
  /**
   * 注销
   */
  export function logout() {
    return request({
      url: '/oauth/logout',
      method: 'delete',
    });
  }

  
  /**
 * 获取用户信息
 */
export function getUserInfo() {
  return request({
    url: "/j-hxjc/user/userInfo",
    method: "get",
  });
}

  /**
 * 修改密码
 */
  export function UserUpdatePwd(data:{
    oldPassword:string;
    newPassword:string;
  }) {
    return request({
      url: "/j-hxjc/user/updatePwd",
      method: "PUT",
      params: data,
    });
  }
  
