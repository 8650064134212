import { createApp } from 'vue'
import { createPinia } from 'pinia';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '../assets/icon/iconfont.css'
import './style.css';
import  { compent  }from "../compent/index";

import App from './App.vue'
import '@assets/index.css';
import router from '@/router';
import '@/permission';

// process.env.NODE_ENV === 'development'
console.log(process.env.NODE_ENV ,'process.env.NODE_ENV','setTimeout')
// appinit('localhost').then(() => {
        createApp(App)
        .use(Antd)
        .use(compent)
        .use(createPinia())
        .use(router)
        .mount('#app')
// })

// AJAXappinfo().then(() => {
//         createApp(App)
//         .use(Antd)
//         .use(createPinia())
//         .use(router)
//         .mount('#app')
// })



