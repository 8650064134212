import { createRouter,  createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import useStore from '@/store';

// export const Layout = () => import('@/layout/index.vue');



// 参数说明: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
// 静态路由
export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name:'indexa',
    components: {
      default:() => import('@/views/data-list/index.vue'),
    },
  },
  {
    path: '/data-list',
    name:'data-list',
    components: {
      default:() => import('@/views/data-list/index.vue'),
    },
  },
  {
    //  cookies:'123123', 没又coolies 转跳至登录
    path: '/login',
    name:'login',
    components: {
      default:() => import('@/views/index/index.vue'),
    },
  },
  {
    path: '/user-agreement',
    name:'user-agreement',
    components: {
      default:() => import('@/views/user-form/user-agreement.vue'),
    },
  },

  {
    path: '/user-privacy',
    name:'user-privacy',
    components: {
      default:() => import('@/views/user-form/user-privacy.vue'),
    },
  },
  {
    path: "/:pathMatch(.*)", // 不识别的path自动匹配404
    redirect: '/login',
  },

];

// 创建路由
const router = createRouter({
  history: createWebHashHistory(),
  // history:createWebHistory('/'),
  routes: constantRoutes as RouteRecordRaw[],
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 })
});

// router.beforeEach((to, from) => {
//   // ...
//   // 返回 false 以取消导航
//   if (
//     // 检查用户是否已登录
//     !sessionStorage.getItem('menu') &&
//     // ❗️ 避免无限重定向
//     to.name !== 'index'
//   ) {
//     // 将用户重定向到登录页面
//     return '/index'
//     // return { name: 'index' }
//   }
// })

// 重置路由
export function resetRouter() {
  const { permission } = useStore();
  permission.routes.forEach(route => {
    const name = route.name;
    if (name && router.hasRoute(name)) {
      router.removeRoute(name);
    }
  });
}

export default router;
