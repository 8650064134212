import JhNav from './jh-nav/jh-nav.vue';
import SearchGroup from './search-group/index.vue';
import JhInput from './jh-input/index.vue';


const compent = {
    install: function (app: { component: (arg0: string, arg1: any) => void; }) {
        app.component('JhNav', JhNav);
        app.component('SearchGroup', SearchGroup);
        app.component('JhInput', JhInput);
    }
};

export{compent};
