
import request from '@/utils/request';
import { AxiosPromise } from 'axios';

interface resType {
error?: any;
// [x: string]: any;
  next:string,
  token?:string;
  captchaId?:string;
  data?:{}
}

/**
 * 登录
 * @param data
 */
export function login(data: any): AxiosPromise<any> {
  return request({
    // so-api/
    url: '/j-hxjc/login',
    method: 'post',
    params: data,
    data:data
    // headers: {
    //   Authorization: 'Basic bWFsbC1hZG1pbi13ZWI6MTIzNDU2', // 客户端信息Base64明文：mall-admin-web:123456
    // },
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    // sso-api/
    // /login
    url: '/j-hxjc/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

/**
 * 注销
 */
export function logout() {
  return request({
    url: '/-auth/oauth/logout',
    method: 'delete',
  });
}

/**
 * 获取图片验证码
 */
export function getCaptcha(): AxiosPromise<any> {
  return request({
    url: '/captcha?t=' + new Date().getTime().toString(),
    method: 'get',
  });
}

export function go(gtoken:string,data?:any): Promise<any>{
  // return new Promise((resolve,reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:true,
  //       token:'1920392',
  //       captchaId:'517e67e5cd91485482e744e3d4b3040f',
  //       next:'init'
  //     })
  //   }, 1000);
  // })
  return request({
    url: '/grant/go',
    method: 'post',
    data:{
      gtoken,
      params:data
    }
    
  });
}

/**
 * 初始化登录页，获取临时获取临时令牌
 * */ 
export function initLogin(obj:string) {
  return request({
    url: '/grant/gen?obj=' + obj,
    method: 'get',
  });
  // /grant/gen
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       next:'userform',
  //       token:'1920392',
  //       captchaId:'517e67e5cd91485482e744e3d4b3040f'
  //     })
  //   }, 1000);
  // })
}


/**
 * 验证用户名密码
 * */ 
export function checkPassword(
  gtoken:string,
  data:{
    account:string,
    password:string,
    // validate:string
}):Promise<resType> {
  return go(gtoken,{
    account:data.account,
    password:data.password,
  })
}

/**
 * @method 初次登录修改密码
 * */ 
export function newPassword(
  gtoken:string,
  data:{
    password:string,
}):Promise<resType> {
  return go(gtoken,{
    password:data.password,
  })
}


/**
 * 验证网易验证码
 * */ 
export function checkWy(
  gtoken:string,
  data:{
    vendor:string,
    cid:string, 
    // token:string
     token:string
}):Promise<resType> {
  return go(gtoken,{
    vendor:data.vendor,
    cid:data.cid, 
    token:data.token
  })
}



/**
 * 验证用户名密码-带网易验证
 * */ 
export function checkPassword_wy(
  gtoken:string,
  data:{
    account:string,
    password:string,
    vendor:string,
    cid:string, 
    token:string
}):Promise<resType> {
  return go(gtoken,{
    account:data.account,
    password:data.password,
    vendor:data.vendor,
    cid:data.cid, 
    token:data.token
  })
}



/**
 * 验证totp
 * */ 
export function checkTotp(gtoken:string,code:string):Promise<resType> {

  return go(gtoken,{totp_code:code})
}

/**
 * 验证网易令牌
 * */ 
// export function checkWy(data:{validate:string}) : Promise<resType>{
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         next:'finish',
//         token:'1920392',
//         captchaId:'517e67e5cd91485482e744e3d4b3040f'
//       })
//     }, 1000);
//   })
//     return go(data)
// }



