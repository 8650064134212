import { defineStore } from 'pinia';
import { UserState } from '@/types/store/user';
import { localStorage } from '@/utils/storage';
import { login, logout } from '@/api/login';
import { getUserInfo } from '@/api/user';
const TokenKey = 'Admin-Token'

const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    token: '',
    cookies:localStorage.get(TokenKey)||'',
    nickname: '',
    avatar: '',
    roles: [],
    perms: [],
    userInfo: {nickName:''}
  }),
  actions: {
    async RESET_STATE() {
      this.$reset();
    },

    /**
     *  获取用户信息（昵称、头像、角色集合、权限集合）
     */
    async getUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfo().then((res) =>{
          this.userInfo = res.data;
          this.nickname = res.data.nickName;
          return  this.userInfo
        })
      });
    },

    /**
     *  注销
     */
    logout() {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            localStorage.remove('token');
            this.RESET_STATE();
            // resetRouter();
            resolve(null);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    /**
     * 清除 Token
     */
    resetToken() {
      return new Promise((resolve) => {
        localStorage.remove('token');
        this.RESET_STATE();
        resolve(null);
      });
    },
  },
});

export default useUserStore;
